// import "../styles/index.scss"

import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
import SEO from "../components/seo"

export default class PageNotFoundTemplate extends React.Component {
  render() {
    return (
      <div>
        <SEO title="Page not found" pathname={this.props.location.pathname} />
        {/* TODO */}
        <Header></Header>
        <main>
          <h1>404</h1>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}
